<template>
  <div>
    <evaluationRequest-list-add-new
      :is-add-new-evaluation-request-sidebar-active.sync="
        isAddNewEvaluationRequestSidebarActive
      "
      :status-options="statusOptions"
      :payment_status-options="payment_statusOptions"
      :type-options="typeOptions"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>عرض</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>مدخلات</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="بحث..."
              />
              <b-button
                v-if="$can('create', 'evaluationRequest')"
                variant="primary"
                to="/evaluation-request/create"
              >
                <span class="text-nowrap">إضافة {{ $t('evaluation_request') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refEvaluationRequestListTable"
        class="position-relative"
        :items="fetchEvaluationRequests"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="لم يتم العثور على سجلات مطابقة"
        :sort-desc.sync="isSortDirDesc"
      >
      <template #cell(reference)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <a :href="`/evaluation-request/${data.item.id}/view`">{{ data.value }}</a>
      </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="$can('read', 'evaluationRequest')"
              :to="{
                name: 'evaluationRequests-view',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">عرض</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('update', 'evaluationRequest')"
              :to="{
                name: 'evaluationRequests-edit',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete', 'evaluationRequest')"
              @click="confirmDelete(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">حذف</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span
              class="text-muted"
            >عرض من {{ dataMeta.from }} إلى {{ dataMeta.to }} من
              {{ dataMeta.of }} مدخلات</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalEvaluationRequest"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import EvaluationRequestListFilters from './EvaluationRequestListFilters.vue';
import useEvaluationRequestList from './useEvaluationRequestList';
import evaluationRequestStore from '../evaluationRequestStore';
import EvaluationRequestListAddNew from './EvaluationRequestListAddNew.vue';

export default {
  components: {
    EvaluationRequestListFilters,
    EvaluationRequestListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    deleteEvaluationRequest(evaluationRequest) {
      store
        .dispatch('evaluationRequest/deleteEvaluationRequest', {
          id: evaluationRequest.id,
        })
        .then((response) => {
          this.$emit('refetch-data');
          this.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الحذف بنجاح',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            evaluationRequestData.value = undefined;
          }
        });
    },
    confirmDelete(evaluationRequest) {
      this.$bvModal
        .msgBoxConfirm(
          `يرجى تأكيد أنك تريد حذف العنصر  ${evaluationRequest.id}.`,
          {
            title: 'يرجى التأكيد',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'نعم',
            cancelTitle: 'لا',
            cancelVariant: 'outline-warning',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then((value) => {
          if (value) {
            this.deleteEvaluationRequest(evaluationRequest);
          }
        });
    },
  },
  setup() {
    const EVALUATIONREQUEST_STORE_MODULE_NAME = 'evaluationRequest';

    // Register evaluationRequest
    if (!store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) {
      store.registerModule(
        EVALUATIONREQUEST_STORE_MODULE_NAME,
        evaluationRequestStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) store.unregisterModule(EVALUATIONREQUEST_STORE_MODULE_NAME);
    });

    const isAddNewEvaluationRequestSidebarActive = ref(false);
    const statusOptions = [
      {
        label: 'في الانتظار',
        value: 'في الانتظار',
      },
      {
        label: 'مقبول',
        value: 'مقبول',
      },
      {
        label: 'مرفوض',
        value: 'مرفوض',
      },
    ];
    const payment_statusOptions = [
      {
        label: 'مدفوع',
        value: 'مدفوع',
      },
      {
        label: 'غير مدفوع',
        value: 'غير مدفوع',
      },
      {
        label: 'غير مستحق الدّفع',
        value: 'غير مستحق الدّفع',
      },
    ];
    const typeOptions = [
      {
        label: 'عقد',
        value: 'عقد',
      },
      {
        label: 'عرض سعر',
        value: 'عرض سعر',
      },
    ];

    const {
      fetchEvaluationRequests,
      tableColumns,
      perPage,
      currentPage,
      totalEvaluationRequest,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEvaluationRequestListTable,
      refetchData,

      // UI
      statusFilter,
      payment_statusFilter,
      typeFilter,
    } = useEvaluationRequestList();

    return {
      // Sidebar
      isAddNewEvaluationRequestSidebarActive,

      fetchEvaluationRequests,
      tableColumns,
      perPage,
      currentPage,
      totalEvaluationRequest,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEvaluationRequestListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      statusOptions,
      statusFilter,
      payment_statusOptions,
      payment_statusFilter,
      typeOptions,
      typeFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
